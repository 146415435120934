//
// spacing.scss
// Extended from Bootstrap
//

// Margin navbar
// Sets the margin value equal to navbar height

.mt-navbar {
  margin-top: $navbar-height;
}