
.navbar-kurre {
    border-top: solid $brand-primary 3px;
    border-bottom: solid #000000 1px; 
    background: $navbar-dark-bg;

    color: #fff;

    //@media (max-width: 768px) {
    //    background: rgba(60, 60, 60, 0.90) !important;
    //}

    .navbar-contact {
        position: absolute;

        top: -5px;
        font-size: 10px;

        right: 15px;

        float: right;
        color: #fff;

        .navbar-phone {
            color: #fff;
            padding-left: 20px;
            padding-right: 20px;
        }
 
        .navbar-contact-button {
            color: #fff;
            background-color: $brand-primary;
            padding: 5px 10px;
            margin: 0px;
        }

    }


    .navbar-brand-kurre {
        float: left;
        padding: 10px;

        //img {
        //    @media (max-width: $grid-float-breakpoint-max) {
        //        height: 30px;
        //    }
        //}
    }

    .nav-kurre {
        //margin-top: 45px;
        font-weight: $font-weight-bolder;

        //a {
        //    color:  $gray-lighter;
        //    text-decoration: none;
        //
        //    &:hover,
        //    &:focus {
        //      color:  $gray-lighter;
        //    }
        //}

        //.active {
        //    a {
        //        color: $brand-primary;
        //        text-decoration: none;
        //
        //        &:hover,
        //        &:focus {
        //            color:  $brand-primary;
        //        }
        //    }
        //}


        //.dropdown-menu {
        //    a {
        //        color: $gray-lighter;
        //        text-decoration: none;
        //
        //        &:hover,
        //        &:focus {
        //            color:  $gray-lighter;
        //        }
        //    }
        //
        //    .active {
        //        a {
        //            color:  $brand-primary;
        //            text-decoration: none;
        //
        //            &:hover,
        //            &:focus {
        //                color: $brand-primary;
        //            }
        //        }
        //    }
        //}
    }
}

.head-spacer {
    height: 100px;

    //@media (max-width: $grid-float-breakpoint-max) {
    //    height: 52px;
    //}

    img {
        width: 100%;
        height: 100%;
    }

}

.relative {
    position: relative;
}
