.mainContent {
   
    .thumbnail {
        img {
            width: auto !important;
            height: auto !important;
            margin: auto;
            padding: 10px;
            text-align:center;
        }
    }
}

.landingContent {
    
    img {
        width: 100%;
        height: auto;
        margin: 10px;
    }

    .thumbnail {
        img {
            width: auto !important;
            height: auto !important;
            margin: auto;
            padding: 10px;
            text-align:center;
        }
    }
}