.separator {
    background-color: $brand-primary;
    padding-top: 20px;
    padding-bottom: 20px;    
    font-size: 30px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.footer {
    background-color: $gray-base;
    padding-top: 40px;
    padding-bottom: 40px;    

    .footerLink {
        color:  $gray-lighter;

        a {
            color: $gray-lighter;
        }

        h5 {
            color: $gray; 
        }

    }

} 