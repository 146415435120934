
.frame-space-before-extra-small {
    margin-top: 15px;
}

.frame-space-before-small {
    margin-top: 25px;
}

.frame-space-before-medium {
    margin-top: 50px;
}

.frame-space-before-large {
    margin-top: 75px;
}

.frame-space-before-extra-large {
    margin-top: 100px;
}


.frame-space-after-extra-small {
    margin-bottom: 15px;
}

.frame-space-after-small {
    margin-bottom: 25px;
}

.frame-space-after-medium {
    margin-bottom: 50px;
}

.frame-space-after-large {
    margin-bottom: 75px;
}

.frame-space-after-extra-large {
    margin-bottom: 100px;
}
