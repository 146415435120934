.carousel-kurre {
    
    .tales {
        width: 100%;
        }

    .carousel-inner{
        width:100%;
        // max-height: 500px;

        .item {
            img {
                width: 100%;
            }
        }

        .item-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: url(../img/overlay_effect_5.png);
            background-size: cover;
        }

        .carousel-caption{
            
            position: absolute;
            bottom: 10px;
            z-index: 10;
            padding-top: 20px;
            padding-bottom: 10px;
            color: $carousel-caption-color;
            text-shadow: $carousel-text-shadow;
            text-shadow: none;
    
            & .btn {
                text-shadow: none; // No shadow for button elements in carousel-caption
            }
        
            h1 {
                color: $carousel-caption-color;
                text-transform: uppercase;
            }

            &.left {
                left: 5%; 
                right: 25%; 
                text-align: left; 

                //@media (max-width: $grid-float-breakpoint-max) {
                //    right: 5%;
                //}
            }
        
            &.right {
                right: 5%; 
                left: 40%;  
                text-align: right;      
                text-shadow: none;

                //@media (max-width: $grid-float-breakpoint-max) {
                //    left: 5%;
                //}

            }
            

            .carousel-box-title {
                font-size: 36px; 
                text-align: right; 
                color: #FFFFFF;     
                
                //@media (max-width: $grid-float-breakpoint-max) {
                //    font-size: 19px;
                //}
            }


            .carousel-box-lead {
                font-size: 18px; 
                max-width: 650px;
                float: right;
                color: #282828;
                padding: 20px; 
                text-align: left; 
                background-color: rgba(255,255,255,0.5);

                //@media (max-width: $grid-float-breakpoint-max) {
                //    font-size: 14px;
                //}

            }

            .carousel-box-button {
                font-size: 15px; 

                text-align: left; 
                text-transform:   uppercase; 

                color: #FFFFFF;                
                background-color: #86b104; 

                font-size: 15px; 
                padding: 10px;
                padding-left: 20px; 
                max-width: 300px;
                float: right;

                //@media (max-width: $grid-float-breakpoint-max) {
                //    font-size: 10px;
                //}

            }

            .carousel-box-button {
                font-size: 15px; 

                text-align: left; 
                text-transform:   uppercase; 

                color: #FFFFFF;                
                background-color: #86b104; 

                font-size: 15px; 
                padding: 10px;
                padding-left: 20px; 
                max-width: 300px;
                float: right;

                //@media (max-width: $grid-float-breakpoint-max) {
                //    font-size: 10px;
                //}

            }

            .carousel-box-button-left {
                font-size: 15px; 

                text-align: left; 
                text-transform:   uppercase; 

                color: #FFFFFF;                
                background-color: #86b104; 

                font-size: 15px; 
                padding: 10px;
                max-width: 260px;
                float: left;

                //@media (max-width: $grid-float-breakpoint-max) {
                //    font-size: 10px;
                //}

            }

        }

    }
    
    border-bottom: solid $brand-primary 2px;
} 

